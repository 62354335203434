<template>
  <div class="merchantcenter_page">
    <div style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      ">
      <div class="page_title">{{ language.Merchantwithdrawal }}</div>
      <el-tabs style="margin-top: 10px" v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="item in currencylist" :key="item.currency" :label="item.currency"
          :name="item.currency"></el-tab-pane>
      </el-tabs>
      <div style="
          border: 1px solid #e4e7ed;
          border-top: none;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
        ">
        <div v-if="showMerchantwithd">
          {{ language.Merchantwithdrawalnotactivatedpleasecontactrelevantpersonnel }}
        </div>
        <div style="display: flex; flex-direction: column; padding-bottom: 40px" v-if="!showMerchantwithd">
          <!-- 钱包地址 -->
          <div style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            ">
            <div :style="languagetype == 'EN'
              ? 'min-width: 200px;white-space: nowrap'
              : 'min-width: 100px;white-space: nowrap'
              ">
              {{ language.Walletaddress }}:
            </div>
            <el-select style="box-sizing: border-box; width: 500px" v-model="dataForm.wallet_id" placeholder="">
              <el-option v-for="item in walletList" :key="item.id" :label="item.wallet_address" :value="item.id">
                <span style="float: left">{{ item.customer_name }}</span>
                <span style="float: right; color: #8492a6;margin-left:15px">{{ item.wallet_address }}</span>
              </el-option>
            </el-select>
          </div>

          <!-- 可用余额 -->
          <div style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            ">
            <div :style="languagetype == 'EN'
              ? 'min-width: 200px;white-space: nowrap'
              : 'min-width: 100px;white-space: nowrap'
              ">
              {{ language.Withdrawablebalance }}:
            </div>
            <el-input style="box-sizing: border-box; width: 500px" v-model="dataForm.Withdrawablebalance" type="text"
              disabled placeholder=""></el-input>
          </div>

          <!-- 商户名称 -->
          <div style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            ">
            <div :style="languagetype == 'EN'
              ? 'min-width: 200px;white-space: nowrap'
              : 'min-width: 100px;white-space: nowrap'
              ">
              {{ language.Merchant_Name }}:
            </div>
            <!-- <el-input style="" v-model="Old_password" placeholder=""></el-input> -->
            <el-input style="box-sizing: border-box; width: 500px" v-model="dataForm.merchant_Name" disabled type="text"
              placeholder=""></el-input>
          </div>

          <!-- 提现金额 -->
          <div style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            ">
            <div :style="languagetype == 'EN'
              ? 'min-width: 200px;white-space: nowrap'
              : 'min-width: 100px;white-space: nowrap'
              ">
              {{ language.Cashwithdrawalamount }}:
            </div>
            <!-- <el-input style="box-sizing: border-box; width: 500px" v-model="dataForm.money" type="text" placeholder=""
              autocomplete="off"></el-input> -->

            <el-input-number style="box-sizing: border-box; width: 500px;" v-model="dataForm.money" :precision="2"
              :step="1" :min="100" :controls="false">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input-number>
            <div style="display: flex; justify-content: center; margin-left:20px">
              <el-button @click="wholeMoney()" type="primary">{{
                language.whole
              }}</el-button>
            </div>
          </div>
          <!-- 支付密码 -->
          <div style="
              position: relative;
              display: flex;
              align-items: center;
              margin-top: 20px;
            ">
            <div :style="languagetype == 'EN'
              ? 'min-width: 200px;white-space: nowrap'
              : 'min-width: 100px;white-space: nowrap'
              ">
              {{ language.paymentpassword }}:
            </div>
            <div style="position: relative">
              <el-input style="box-sizing: border-box; width: 500px" v-model="dataForm.pay_pwd"
                :type="passwordicon ? 'password' : 'text'" placeholder="" autocomplete="off"></el-input>
              <span v-if="passwordicon" style="" @click="eyebtn5()" class="iconeye3"></span>
              <span v-else style="" @click="eyebtn5()" class="iconeye4"></span>
            </div>
            <div style="margin-left: 20px">
              <span style="text-decoration: underline; color: #FD6303; cursor: pointer" @click="gochangepass()">{{
                language.GotoEdit }}</span>
            </div>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 20px">
            <el-button @click="addbank()" type="primary">{{
              language.determine
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  info,
  wallet,
  user,
  withadd,
} from "../../api/index.js";
export default {
  data() {
    return {
      passwordicon: true,
      activeName: "",
      currencylist: [
        {
          currency: "INR",
          country: "印度"
        }
      ],
      dataForm: {
        type: 2,
        wallet_id: '',
        Withdrawablebalance: '',
        merchant_Name: '',
        money: '100',
        pay_pwd: '',
      },
      walletList: [],
      languagetype: "",
      language: "",
      info: "",

      showMerchantwithd: false,
    };
  },
  created() {
    // 判断语言
    this.changelanguage();
    info().then((res) => {
      this.info = res.data;
    });
    this.activeName = this.currencylist[0].currency
    this.getWallet()
    this.user();
  },
  watch: {
    // 判断语言
    "$store.state.language"() {
      this.changelanguage();
    },
  },
  methods: {
    // 获取账户信息
    user() {
      user().then((res) => {
        if (res.code == 1) {
          this.dataForm.Withdrawablebalance = res.data.userinfo.money;
          this.dataForm.merchant_Name = res.data.userinfo.nickname;
        }
      });
    },
    // 获取钱包地址信息
    async getWallet() {
      try {
        let { code, data } = await wallet()
        if (code == 1) {
          this.walletList = data.list
        }
      } catch (error) {
        console.log(error)
      }
    },
    wholeMoney() {
      this.dataForm.money = this.dataForm.Withdrawablebalance
    },
    eyebtn5() {
      this.passwordicon = !this.passwordicon;
    },
    handleClick(tab) {
      this.dataForm = {
        type: 2,
        wallet_id: '',
        Withdrawablebalance: '',
        merchant_Name: '',
        money: '100',
        pay_pwd: '',
      }
      this.activeName = tab.label;
    },
    gochangepass() {
      this.$router.push("/changepassword");
    },
    // 提现
    addbank() {
      if (!this.dataForm.wallet_id) {
        this.$message({
          message: this.language.Walletaddress + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      if (this.dataForm.money < 100) {
        this.$message({
          message: this.language.Cashwithdrawalamount + this.language.MustBeGreaterThan + "100",
          type: "warning",
        });
        return;
      }
      if (!this.dataForm.pay_pwd) {
        this.$message({
          message: this.language.paymentpassword + this.language.cannotbeempty,
          type: "warning",
        });
        return;
      }
      withadd({
        ...this.dataForm,
        currency: this.activeName
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: this.language.Successfullyapplied,
            type: "success",
          });
          this.dataForm = {
            type: 2,
            wallet_id: '',
            Withdrawablebalance: '',
            merchant_Name: '',
            money: '100',
            pay_pwd: '',
          }
        }
      });
    },
    // 获取语言
    changelanguage() {
      this.languagetype = this.$store.state.language;
      this.language = this.$units();
    },
  },
};
</script>
<style scoped>
.merchantcenter_page {
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.iconeye3 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 9px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat 0 -43px;
}

.iconeye4 {
  display: inline-block;
  width: 33px;
  height: 22px;
  position: absolute;
  z-index: 9;
  top: 9px;
  cursor: pointer;
  right: 0;
  background: url("../../static/icon-login.png") no-repeat -55px -43px;
}

* /deep/ .el-tabs__header {
  margin: 0;
}
</style>
